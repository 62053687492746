import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'

import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { Select, Menu, Dropdown } from 'antd'
import { GlobalOutlined } from '@ant-design/icons'

import Header from '../../components/header/header.component.jsx'
import logo from '../../images/footer-logo.svg'

const { Option } = Select

export const signLinks = [
	{ to: '/', text: 'Log In' },
	{ to: '/signup', text: 'Sign Up' },
]

export const navLinks = [
	{
		linkTo: '/',
		title: <Trans>Home</Trans>,
	},
	{
		linkTo: '/features',
		title: 'Features',
	},

	{
		linkTo: '/pricing',
		title: 'Pricing',
	},
	{
		linkTo: '/blogs',
		title: 'Blogs',
	},
	{
		linkTo: '/contactus',
		title: <Trans>Contact Us</Trans>,
	},
	{
		linkTo: 'faqs',
		title: 'FAQs',
	},
]
export const buttonStyle = {
	height: '52px',
	color: '#0097c2',
	backgroundColor: '#E5F5F9',
	fontSize: '14px',
}

const LanguageSwitcher = ({ languages, changeLanguage }) => {
	const { i18n } = useTranslation()

	const handleChange = lng => {
		changeLanguage(lng)
	}

	const menu = (
		<Menu>
			{languages.map(lng => (
				<Menu.Item key={lng} onClick={() => handleChange(lng)}>
					{lng === 'en' ? 'English' : 'العربية'}
				</Menu.Item>
			))}
		</Menu>
	)

	return (
		<Dropdown overlay={menu} placement="bottomRight">
			<span className="langDropdown">
				<GlobalOutlined
					style={{
						marginRight: '8px',
						verticalAlign: 'unset',
					}}
				/>
				{i18n.language === 'en' ? 'English' : 'العربية'}
			</span>
		</Dropdown>
	)
}

export const Navbar = ({ path }) => {
	const location = useLocation()
	const { pathname } = location
	const { languages, changeLanguage } = useI18next()

	const { t, i18n } = useTranslation()

	const [scrolling, setScrolling] = useState(false)
	const [scrollTop, setScrollTop] = useState(0)
	useEffect(() => {
		function onScroll() {
			let currentPosition = window.pageYOffset
			if (currentPosition === 0) {
				// downscroll code
				setScrolling(false)
			} else if (currentPosition <= scrollTop) {
				// upscroll code
				setScrolling(false)
			} else if (currentPosition >= 0) {
				// upscroll code
				setScrolling(true)
			}
			setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
		}

		window.addEventListener('scroll', onScroll)
		return () => window.removeEventListener('scroll', onScroll)
	}, [scrollTop])

	return (
		<Header
			// LanguageSwitcher={<LanguageSwitcher languages={languages} changeLanguage={changeLanguage} />}
			languageSwitcher={<LanguageSwitcher languages={languages} changeLanguage={changeLanguage} />}
			scrolling={scrolling}
			scrollTop={scrollTop}
			logo={logo}
			logoLink="/"
			navLinks={navLinks}
			loginTo={signLinks[0].to}
			loginText={signLinks[0].text}
			buttonStyle={buttonStyle}
			signupTo={signLinks[1].to}
			signupText={signLinks[1].text}
		></Header>
	)
}
